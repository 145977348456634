import request from '@/utils/axios'
import { getCookie } from "@/utils/cookie";

// import requestFlow from '@/utils/axios-flow'
export function getAccessToken() {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/auth/get-access-token' },
        headers: {
            isToken: false,
        },
        data: {
            appid: '5NnDQmEv94ZLNVA',
            appsecret: "1nrhJKzmK0tsq1SkPBqVqJ8tA4bhVTnQ"
        }
    })
}
export function userSendMsg(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/send-msg' },
        data: data
    })
}
export function emailSendMsg(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/send-email' },
        data: data
    })
}
export function userLogin(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/login' },
        data: data
    })
}
export function resetPassWord(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/reset-pass-word' },
        data: data
    })
}
export function applyForProbation(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/apply-for-probation' },
        data: data
    })
}
export function meallist(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/meal/list' },
        data: data
    })
}
export function purchasecode(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/meal/get-purchase-qr-code' },
        data: data
    })
}
export function aliCallBack(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/meal/ali-pay-call-back' },
        data: data
    })
}
export const defaultData = (url, params, method) => {
    // let urls=process.env.NODE_ENV=== 'production' ?url:'/api'+url
    return new Promise((resolve, reject) => {
        if (!params) {
            params = {}
        }
        params. access_token=getCookie("access_token");
        //   let data = qs.stringify(params);
        let data = params
        return request({
            data,
            url: '/api/' + url,
            method: method || "post"
        }).then(res => {
            console.log(res)
            if (res.status == 200) {
                if (res.data.code != 10010 && res.data.code != 1) {
                    resolve(res)
                } else if (res.data.code == 1) {
                    // 接口一些错误信息
                    resolve(res)
                } else {
                    // _this.$notify({
                    //   message: res.data.msg,
                    //   color: '#fff',
                    // });
                }
            } else {
                reject(res)
            }
        }).catch(err => {
            // _this.$notify({
            //   message: '服务器异常，请稍后重试',
            //   color: '#fff',
            // });
            reject(err)
        })
    })

};
// export function getAgent(data) {
//     return requestFlow({
//         url: '/GetAgent',
//         method: 'post',
//         headers: {
//             "Content-Type": "application/json" // 如果写成contentType会报错
//         },
//         data: data
//     })
// }
// export function createAgent(data) {
//     return requestFlow({
//         url: '/CreateAgent',
//         method: 'post',
//         headers: {
//             "Content-Type": "application/json" // 如果写成contentType会报错
//         },
//         data: data
//     })
// }
// export function updateAgent(data) {
//     return requestFlow({
//         url: '/UpdateAgent',
//         method: 'post',
//         headers: {
//             "Content-Type": "application/json" // 如果写成contentType会报错
//         },
//         data: data
//     })
// }
