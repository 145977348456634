<template>
  <div id="app">
    <svgList />
    <router-view />
    <el-dialog title="" :visible.sync="dialogVisible" @close="handleClose" width='444px'  class="login_dialog">
      <loginDialogPage />
    </el-dialog>
    <!-- <loginDIalog /> -->
  </div>
</template>
<script>
import loginDIalog from "@/components/loginDIalog";
import loginDialogPage from "@/views/loginDialogPage";

import svgList from "@/components/svgList";
export default {
  components: {
    loginDIalog,
    svgList,
    loginDialogPage
  },
  data() {
    return {
      loginDisplay: this.$store.state.loginDisplay,//
      dialogVisible:false
    };
  },
  computed: {
    setLoginDisplay() {
      return this.$store.state.loginDisplay
    },
  },
  watch: {
    setLoginDisplay: function (data) {
      this.loginDisplay = data
      this.init()
    }
  },
  mounted() {
  },
  methods:{
    init(){
       if(this.loginDisplay){
        this.dialogVisible=true 
      }else{
        this.dialogVisible=false
      }
    },
    handleClose(){
      console.log(6543)
        this.dialogVisible=false
        this.$store.commit("setLoginDisplay", false);
    },
  }
};
</script>
<style scoped>
.login_dialog /deep/ .el-dialog__headerbtn{
  z-index: 100;
}
.login_dialog /deep/ .el-dialog {
  border-radius: 30px;
}
.login_dialog /deep/ .el-dialog__header {
  padding: 0;
}
.login_dialog /deep/ .el-dialog__body {
  padding: 0;
}
</style>
