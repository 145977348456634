<template>
  <div>
    <header class="header">
      <!-- <svgList /> -->
      <div class="section">
        <h1 class="font-size-0 flex items-center">
          <router-link :to="{ name: 'home' }">
            <img class="logo" alt="元空" src="../../assets/images/logo.png" />元空
          </router-link>
          <!-- <span class="beta-version">Beta</span> -->
        </h1>
        <nav class="nav-menu">
          <div class="nav-menu-item">
            <div class="nav-menu-item-inner">
              <!-- <router-link :to="{ name: 'home' }"> 产品 </router-link> -->
              <a href="javascript:;">产品</a>
              <svg class="iconpark-icon iconpark-down">
                <use href="#c-down"></use>
              </svg>
              <div class="nav-sub-menu-container">
                <div class="sub-menu-wrapper">
                  <div class="sub-menu-item-box" @click="godetail(1)">
                    <div>
                      <h5 class="sub-menu-title">酷表Chat Excel</h5>
                      <p class="sub-menu-desc">
                        仅通过聊天，就最控制Excel表格
                      </p>
                    </div>
                  </div>
                  <div class="sub-menu-item-box" @click="godetail(2)">
                    <div>
                      <h5 class="sub-menu-title">AI VOC</h5>
                      <p class="sub-menu-desc">
                        借助AI挖掘数据，对话式获取产品/消费者/市场洞察
                      </p>
                    </div>
                  </div>
                  <!-- <div class="sub-menu-item-box" @click="godetail(4)">
                    <div>
                      <h5 class="sub-menu-title">AI 营销辅助决策</h5>
                      <p class="sub-menu-desc">
                        借助AI挖掘数据，对话式获取产品/消费者/市场洞察
                      </p>
                    </div>
                  </div> -->
                  <div class="sub-menu-item-box" @click="godetail(3)">
                    <div>
                      <h5 class="sub-menu-title">AI 智能客服</h5>
                      <p class="sub-menu-desc">
                        全渠道、全流程覆盖，提供智能化、个性化服务
                      </p>
                    </div>
                  </div>

                  <!-- <router-link class="sub-menu-item-box" :to="{ name: 'popularFeelings' }">
                  <img src="@/assets/images/navigation04.png" alt="" style="width: 16px; height: 16px;margin-top: 5px;" />

                  <div>
                    <h5 class="sub-menu-title">AIGA辅助决策</h5>
                    <p class="sub-menu-desc">
                      后链路数据对接,与电商订单数据整合，形成决策闭环
                    </p>
                  </div>
                </router-link> -->
                </div>
              </div>
            </div>
          </div>
          <div class="nav-menu-item">
            <div class="nav-menu-item-inner">
              <!-- <a href="javascript:;">价格</a> -->
              <a  @click="navigateTo('#price')">价格</a>
              <!-- <a href="#section2" @click="navigateTo('#section2')">价格</a> -->
            </div>
          </div>
          <div class="nav-menu-item">
            <div class="nav-menu-item-inner">
              <!-- <a href="https://sheet.chatexcel.com/#/home?tab=usingTutorials" target="_blank" >使用教程</a> -->
              <!-- <a href="./tutorialPage" >使用教程</a> -->
              <router-link :to="{ name: 'tutorialPage' }"> 使用教程 </router-link>
            </div>
          </div>
          <div class="nav-menu-item" style="display:none">
            <div class="nav-menu-item-inner">
              <a href="javascript:;">解决方案</a>
              <svg class="iconpark-icon iconpark-down">
                <use href="#c-down"></use>
              </svg>
            </div>
            <div class="nav-sub-menu-container">
              <div class="sub-menu-wrapper">
                <router-link class="sub-menu-item-box" :to="{ name: 'category' }">
                  <img src="@/assets/images/navigation01.png" alt="" style="width: 16px; height: 16px;margin-top: 5px;" />
                  <!-- <svg class="iconpark-icon" style="color: #7c48ee">
                    <use href="#pinleidapan"></use>
                  </svg> -->
                  <div>
                    <h5 class="sub-menu-title">消费者画像精准刻画</h5>
                    <p class="sub-menu-desc">
                      聆听消费者反馈，刻画用户画像，定位需求与痛点
                    </p>
                  </div>
                </router-link>
                <router-link class="sub-menu-item-box" :to="{ name: 'marketingStrategy' }">
                  <img src="@/assets/images/navigation02.png" alt="" style="width: 16px; height: 16px;margin-top: 5px;" />

                  <div>
                    <h5 class="sub-menu-title">品牌动态实时捕捉</h5>
                    <p class="sub-menu-desc">
                      收集品牌最新信息。洞察舆情变化，预测传播走向
                    </p>
                  </div>
                </router-link>
                <router-link class="sub-menu-item-box" :to="{ name: 'customerInsight' }">
                  <img src="@/assets/images/navigation03.png" alt="" style="width: 16px; height: 16px;margin-top: 5px;" />

                  <div>
                    <h5 class="sub-menu-title">“趋势达人”发现连接</h5>
                    <p class="sub-menu-desc">
                      发现不一样的人群，寻找人群中的KP
                    </p>
                  </div>
                </router-link>
                <router-link class="sub-menu-item-box" :to="{ name: 'popularFeelings' }">
                  <img src="@/assets/images/navigation04.png" alt="" style="width: 16px; height: 16px;margin-top: 5px;" />

                  <div>
                    <h5 class="sub-menu-title">AIGA辅助决策</h5>
                    <p class="sub-menu-desc">
                      后链路数据对接,与电商订单数据整合，形成决策闭环
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </nav>
        <div class="header-right">
          <button v-if="!$store.state.login_token" @click="loginChange" class="login-btn">
            注册 / 登录
          </button>
          <div v-else class="user-info">
            <button class="get-started-btn" @click="$toUse()">开始使用</button>
            <div class="user-card">
              <img class="user-avatar" alt="元空" src="../../assets/images/image-avatar.png" />
              <span class="account">
                {{ $store.state.mobile }}
              </span>
              <svg class="iconpark-icon">
                <use href="#c-sanjiao-xia"></use>
              </svg>
              <div class="log-out-box">
                <div class="log-out">
                  <span id="loginOut" @click="logOut">退出</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import svgList from "@/components/svgList";
import { clearCookies, getCookie,setCookie } from "@/utils/cookie";
export default {
  name: "navHeader",
  components: { svgList },
  data() {
    return {};
  },
  mounted() { },
  methods: {
    godetail(type) {
      if (getCookie("access_token")) {
        if (type == 1) {
             location.href = 'https://www.chatexcel.com/'
          // window.open('https://betawww.chatexcel.com/')
        } else if (type == 2) {
          this.$router.push({
            name: 'illustrateAI',
            query: {
              pagename:'vocPage'
            }
          })
          // window.open('https://workspace.chatexcel.com/')
        } else if (type == 3) {
           this.$router.push({
            name: 'illustrateAI',
            query: {
              pagename:'servicePage'
            }
          })
          // window.open('https://zs.yuankongai.com/')
        } else if (type == 4) {
          location.href = 'https://yuankongai.com/'
        }
      } else {
        this.$store.commit("setLoginDisplay", true);
      }
    },
    loginChange() {
      this.$store.commit("setLoginDisplay", true);
    },
    logOut() {
      this.$confirm("此操作将退出当前登录账号, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          clearCookies();
          this.$store.commit("setloginToken", "");
           setCookie('userInfo', '')
          localStorage.clear();
        })
        .catch(() => { });
    },
    navigateTo(anchor) {
      // 获取当前路由的名称
      const currentRouteName = this.$route.name;

      // 如果当前路由是主页
      if (currentRouteName === 'home') {
        // 滚动到指定的锚点
        const element = document.querySelector(anchor);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          // this.$router.replace({ name: 'home' })
        }
      } 
      // else {
      //   // 否则导航到主页
      //   this.$router.push({ name: 'home' }).then(() => {
      //     const element = document.querySelector(anchor);
      //     if (element) {
      //       element.scrollIntoView({ behavior: 'smooth' });
      //     }
      //   });
      // }
    }
  },
};
</script>

<style>
</style>